import React from "react"
import { Link } from "gatsby"
import Modal from './modal'
import CSS from "./connect.module.css"

function encode(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&")
}

class Connect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isValidated: false,
            isOpen: false,
            name: "",
            email: "",
            message: "",
            permission: ""
        }
    }

    toggleModal = () => this.setState({ isOpen: !this.state.isOpen })

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleCheckbox = () => {
        this.state.permission === "" ? this.setState({permission: "checked"}) : this.setState({permission: ""})
    }

    handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state,
            }),
        })
            // .then(() => navigate(form.getAttribute("action")))
            .then(() => {
                this.setState({isOpen: true})
                // this is where the form needs clearing
                this.setState({name: "", email: "", message: "", permission: ""})
            })
            .catch(error => alert(error))
    }

    render() {
        return (
            <>
                <div className={CSS.wrapper}>
                    <h2 className={`${CSS.heading} ${CSS.left}`}>
                        onnect<span className={CSS.slash}>/</span>
                    </h2>
                    <h2 className={`${CSS.heading} ${CSS.right}`}>co</h2>
                </div>

                <div className={CSS.container}>
                    <div className={CSS.formWrapper}>
                        <h3>Say 'hello' or, better still, tell us about an exciting project you'd like to discuss.</h3>

                        <form
                            name="contact"
                            method="post"
                            action="/confirm/"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            onSubmit={this.handleSubmit}
                        >
                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                            <input
                                type="hidden"
                                name="form-name"
                                value="contact"
                            />
                            <div hidden>
                                <label>
                                    Don’t fill this out:{" "}
                                    <input
                                        name="bot-field"
                                        onChange={this.handleChange}
                                    />
                                </label>
                            </div>

                            <div className="field">
                                <label className="label" htmlFor={"name"}>
                                    Your name
                                </label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type={"text"}
                                        name={"name"}
                                        onChange={this.handleChange}
                                        id={"name"}
                                        value={this.state.name}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label" htmlFor={"email"}>
                                    Email
                                </label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type={"email"}
                                        name={"email"}
                                        onChange={this.handleChange}
                                        id={"email"}
                                        value={this.state.email}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <div className="field">
                                <label className="label" htmlFor={"message"}>
                                    Message
                                </label>
                                <div className="control">
                                    <textarea
                                        className="textarea"
                                        name={"message"}
                                        onChange={this.handleChange}
                                        id={"message"}
                                        value={this.state.message}
                                        required={true}
                                    />
                                </div>
                            </div>


                            <div className="field">
                                <p><strong>We take <Link to="/legal">privacy</Link> seriously.</strong></p>
                                <p>In order to respond to your message, and comply with GDPR, we must ask your permission to contact you using the details you are about to provide.</p>
                            </div>


                            <div className="field">
                                <input 
                                    class="exp__input" 
                                    type="checkbox" 
                                    id="permitted" 
                                    name="permitted" 
                                    value=""
                                    required={true}
                                    onChange={this.handleCheckbox}
                                    checked={this.state.permission}
                                />
                                <label class="exp__label" htmlFor="permitted">
                                    <span class="exp__checkbox"></span>I grant Onedotsix permission to contact me.
                                </label>
                            </div>


                            <div className="action">
                                <button className="button" type="submit">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <Modal show={this.state.isOpen} onClose={this.toggleModal}>
                    <div className={CSS.modalMessageContainer}>
                        <h3 className={CSS.modalTitleTop}>Thank</h3>
                        <p>Thank you for reaching out to us. We will be in touch promptly.</p>
                        <h3 className={CSS.modalTitleBottom}><span className={CSS.slash}>/</span>you</h3>
                    </div>
                </Modal>
            </>
        )
    }
}

export default Connect
