import React from "react"
import PropTypes from "prop-types"

import CSS from './modal.module.css'

class Modal extends React.Component {

    render() {
        return (
            <div className={`${CSS.overlay} ${this.props.show ? CSS.active : ""}`}>
                <div className={CSS.box}>
                    {this.props.children}
                    <button className={CSS.closeButton} onClick={this.props.onClose} aria-label="close" />
                </div>
            </div>
        )
    }
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    children: PropTypes.node,
}

export default Modal
