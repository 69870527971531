import React from 'react'

import CSS from "./number.module.css"

const Number = ( props ) => {
    return (
        <div className={CSS.wrapper}>
            <h2 className={CSS.number}>{props.title}<span className={CSS.slash}>/</span></h2>
        </div>
    )
}

export default Number