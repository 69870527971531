import React, { useState } from "react"

import Number from "./number"
import Image from "./image"
import Carousel from 'nuka-carousel'

import CSS from "./project-slider.module.css"

const ProjectSlider = props => {

    const [index, setIndex] = useState(0)

    const handleNavigation = (step) => {
        if ( step === 'next' && index < props.projects.length-1 ) {
            setIndex( index + 1 )
        }
        if ( step === 'prev' && index > 0 ) {
            setIndex( index - 1 )
        }
    }

    const handleLayoutDirection = () => {
        if ( props.direction === "flipped" ) {
            return CSS.flipped
        }
    }

    const handleTheme = () => {
        if ( props.theme === "dark" ) {
            return CSS.dark
        }
    }


    
    return (
        <div className={`${CSS.container} ${handleLayoutDirection()} ${handleTheme()}`}>
            <Number title={props.number} />

            <div className={CSS.grid}>

                <div className={CSS.category}>
                    <h3>{props.category}</h3>
                </div>

                {/* caption slider */}
                <div className={CSS.caption}>
                    <Carousel slideIndex={index} vertical withoutControls dragging={false} swiping={false}>
                        { props.projects.map( (project) => <p key={project.hero}>{project.caption}</p> ) }
                    </Carousel>
                </div>

                {/* hero slider */}
                <div className={CSS.hero}>
                    <Carousel slideIndex={index} withoutControls dragging={false} swiping={false}>
                        { props.projects.map( (project) => <Image key={project.hero} filename={project.hero} alt="brochure" /> ) }
                    </Carousel>
                </div>

                {/* inset slider */}
                <div className={CSS.inset}>
                    <Carousel slideIndex={index} withoutControls dragging={false} swiping={false}>
                        { props.projects.map( (project) => <Image key={project.hero} filename={project.inset} alt="brochure" /> ) }
                    </Carousel>
                </div>

                <div className={CSS.prev}>
                    <button 
                        className={`${CSS.button} ${CSS.prevButton}`}  
                        onClick={() => handleNavigation('prev')} aria-label="prev" 
                        disabled={ index <= 0 ? "disabled" : null }
                    />
                </div>

                <div className={CSS.next}>
                    <button 
                        className={`${CSS.button} ${CSS.nextButton}`} 
                        onClick={() => handleNavigation('next')} aria-label="next" 
                        disabled={ index >= props.projects.length-1 ? "disabled" : null }
                    />
                </div>
                
            </div>
        </div>
    )
}

export default ProjectSlider
