import React from 'react'

import CSS from './mission.module.scss'

const Mission = props => {
    return (

        <section className={CSS.block}>
            <div className={CSS.container}>
                
                <div className={CSS.grid}>
                
                    <h2 className={CSS.mission}>Onedotsix is an online studio offering unique solutions for brand design and strategy, digital design, plus original perspectives on a wide spectrum of other design disciplines.</h2>

                    <ul className={CSS.categories}>
                        <li>Brand design and strategy</li>
                        <li>Digital design</li>
                        <li>Design for print</li>
                        <li>Packaging concepts</li>
                        <li>Illustration</li>
                        <li>Interior space concepts</li>
                        <li>Product visualisation</li>
                    </ul>

                </div>

            </div>
        </section>

    )
}

export default Mission