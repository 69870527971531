import React from 'react'

import CSS from './hero.module.css'

const Hero = () => {

    return (
        <div className={CSS.wrapper}>
            <h1 className={CSS.name}>
                <span className={CSS.one}>one</span><span className={CSS.dotsix}>dotsix</span>
            </h1>
        </div>
    )
}

export default Hero