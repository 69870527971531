import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions'

import Layout from "../global/layout"
import SEO from "../global/seo"

import Hero from "../components/hero"
import Mission from "../components/mission"
import ProjectSlider from "../components/project-slider"
import Connect from "../components/connect"

const IndexPage = () => (
    <PageTransition>

        <Layout>
            <SEO title="onedotsix" />

            <Hero />

            <Mission />

            
            <div style={{height: `10vw`}}></div>


            <ProjectSlider
                number="01"
                category="Identity"
                projects = {[
                    { 
                        caption: "Osé flame-grill restaurant",
                        hero: "ose-hero.jpg",
                        inset: "ose-inset.jpg"
                    },
                    { 
                        caption: "Blue Pool Road Hong Kong",
                        hero: "bluepool-hero.jpg",
                        inset: "bluepool-inset.jpg"
                    }
                ]} 
            />

            <ProjectSlider
                number="02"
                direction="flipped"
                category="Brand design and strategy"
                projects = {[
                    { 
                        caption: "2 Hyde Park Square Residential Development",
                        hero: "2hps-hero.jpg",
                        inset: "2hps-inset.jpg"
                    },
                    { 
                        caption: "Azulara luxury resort, Madeira",
                        hero: "azulara-hero.jpg",
                        inset: "azulara-inset.jpg"
                    },
                    { 
                        caption: "Hibiki No Mori Golf Resort",
                        hero: "hnm-hero.jpg",
                        inset: "hnm-inset.jpg"
                    },
                    { 
                        caption: "The House Barbados",
                        hero: "beach-house-hero.jpg",
                        inset: "beach-house-inset.jpg"
                    }
                ]}
            />
            
            <ProjectSlider
                number="03"
                category="Design for print"
                projects = {[
                    { 
                        caption: "Levi Strauss fit book",
                        hero: "levis-hero.jpg",
                        inset: "levis-inset.jpg"
                    },
                    { 
                        caption: "L'hotel Hong Kong",
                        hero: "lhotel-hero.jpg",
                        inset: "lhotel-inset.jpg"
                    }
                ]} 
            />

            <ProjectSlider
                number="04"
                direction="flipped"
                theme="light"
                category="Retail space / interior design"
                projects = {[
                    { 
                        caption: "Schiesser",
                        hero: "schiesser-hero.jpg",
                        inset: "schiesser-inset.jpg"
                    },
                    { 
                        caption: "Azulara marketing suite",
                        hero: "azulara-marketing-suite-hero.jpg",
                        inset: "azulara-marketing-suite-inset.jpg"
                    }
                ]}
            />

            <ProjectSlider
                number="05"
                theme="light"
                category="Product visualisation"
                projects = {[
                    { 
                        caption: "Cogito connected watch",
                        hero: "cogito-hero.jpg",
                        inset: "cogito-inset.jpg"
                    },
                    { 
                        caption: "Cinelli Strato",
                        hero: "cinelli-hero.jpg",
                        inset: "cinelli-inset.jpg"
                    },
                    { 
                        caption: "Cambridge Audio SX50 speakers",
                        hero: "cambridge-audio-hero.jpg",
                        inset: "cambridge-audio-inset.jpg"
                    }
                ]}
            />

            <Connect />

        </Layout>
        
    </PageTransition>
    
)

export default IndexPage